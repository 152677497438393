// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-download-phil-js": () => import("./../../../src/pages/download_phil.js" /* webpackChunkName: "component---src-pages-download-phil-js" */),
  "component---src-pages-download-stailey-js": () => import("./../../../src/pages/download_stailey.js" /* webpackChunkName: "component---src-pages-download-stailey-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-recipes-phil-js": () => import("./../../../src/pages/recipes/phil.js" /* webpackChunkName: "component---src-pages-recipes-phil-js" */),
  "component---src-pages-recipes-stailey-js": () => import("./../../../src/pages/recipes/stailey.js" /* webpackChunkName: "component---src-pages-recipes-stailey-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */)
}

